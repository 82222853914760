<template>
  <div class="gmb-settings">
    <div class="gmb-settings__toolbar" :class="{ 'theme--dark': $vuetify.theme.dark }">
      <UiTitle class="gmb-settings__title" :small="true" :title="$t('gmb.admin.settings.gmb.country.label')"></UiTitle>
      <UiContainer no-padding>
        <div class="gmb-settings__table-container">
          <v-data-table
            class="gmb-settings__table-container__table"
            :headers="headers"
            :items="countriesOptions"
            :items-per-page="5"
            height="100%"
            item-key="countries"
            fixed-header
          />
        </div>
      </UiContainer>

      <v-divider class="tw-mt-1" />

      <UiTitle class="gmb-settings__title" :small="true" :title="$t('gmb.admin.settings.gmb.language.label')"></UiTitle>
      <UiContainer no-padding>
        <div class="gmb-settings__table-container">
          <v-data-table
            class="gmb-settings__table-container__table"
            :headers="headers"
            :items="languageCodesOptions"
            :items-per-page="5"
            height="100%"
            item-key="countries"
            fixed-header
          />
        </div>
      </UiContainer>
    </div>
  </div>
</template>

<script>
import { mdiFlagVariant, mdiWeb } from '@mdi/js'
import UiTitle from '@/components/UI/Title'
import UiContainer from '@/components/UI/Container.vue'

export default {
  name: 'GmbSettings',
  components: {
    UiTitle,
    UiContainer,
  },
  props: {
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
    NotAvailablesCountries: {
      type: Object,
      required: true,
    },
    languageCodes: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    icons: {
      mdiFlagVariant,
      mdiWeb,
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: 'id',
          value: 'id',
          sortable: false,
        },
        {
          text: 'label',
          value: 'label',
          sortable: false,
        },
      ]
    },
    countriesOptions() {
      return Object.entries(this.NotAvailablesCountries).map(obj => ({
        id: obj[0],
        label: obj[1],
      }))
    },
    languageCodesOptions() {
      return Object.entries(this.languageCodes).map(obj => ({
        id: obj[0],
        label: obj[1],
      }))
    },
  },
  methods: {},
  beforeDestroy() {
    this.$emit('clearStore')
  },
}
</script>

<style lang="scss" scoped>
.gmb-settings {
  @apply tw-flex tw-flex-col tw-h-full;

  &__title {
    @apply tw-ml-5 tw-mb-4 tw-mt-6;
  }

  &__toolbar {
    &.theme--dark {
      border-bottom: 1px solid rgba(map-deep-get($material-dark, 'banner', 'border'), 0.12);
      background: map-get($material-dark, 'banner', 'background');
    }
  }
}
</style>
