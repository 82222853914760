<template>
  <v-dialog :value="dialog" :max-width="width" class="ui-modal" scrollable @keydown.esc="close" @click:outside="close">
    <v-card class="ui-modal__card">
      <div class="ui-modal__card__header">
        <slot name="header">
          <UiTitle :title="title" />
          <UiSubtitle :subtitle="subtitle" />
        </slot>
      </div>
      <v-divider />
      <div class="ui-modal__card__body">
        <slot name="body"></slot>
      </div>
      <v-divider />
      <div
        class="ui-modal__card__actions"
        :class="{
          'ui-modal__card__actions--left': actionsLayout === 'left',
          'ui-modal__card__actions--center': actionsLayout === 'center',
          'ui-modal__card__actions--right': actionsLayout === 'right',
        }"
      >
        <slot name="actions">
          <v-btn rounded plain @click="close">
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn rounded color="primary" :loading="saving" :disabled="saving" @click="save">
            {{ $t('button.save') }}
          </v-btn>
        </slot>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'

export default {
  name: 'UiModal',
  components: {
    UiTitle,
    UiSubtitle,
  },
  props: {
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    width: {
      type: String,
      default: '700',
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    actionsLayout: {
      type: String,
      default: '',
    },
  },
  methods: {
    save() {
      this.$emit('save')
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-modal {
  &__card {
    @apply tw-rounded-2xl;

    &__header,
    &__actions {
      @apply tw-p-4 sm:tw-px-6;
    }

    &__body {
      @apply tw-h-full tw-overflow-y-auto tw-p-4 sm:tw-p-6;
    }

    &__actions {
      @apply tw-flex tw-justify-between;

      &--left {
        @apply tw-justify-start;
      }

      &--center {
        @apply tw-justify-center;
      }

      &--right {
        @apply tw-justify-end;
      }
    }
  }
}
</style>
