<template>
  <ui-modal-multiple-list
    class="ui-modal-admins-and-notifications"
    :multiple-list="multipleList"
    :headers="headers"
    :tabs="tabs"
    @tabChanged="tabChanged"
    @close="close"
  >
    <template v-slot:header>
      <div class="tw-flex tw-justify-between tw-items-center">
        <div>
          <UiTitle :title="$t('modal.multipleList.title')" />
          <UiSubtitle :subtitle="subtitleAdmins" v-if="currentTab === 'admin'" />
          <UiSubtitle :subtitle="subtitleNotifications" v-if="currentTab === 'notification'" />
        </div>
        <div>
          <v-btn
            color="primary"
            rounded
            v-if="currentTab === 'notification' && multipleList[1].length === 0"
            @click="emitActivateNotifications"
          >
            {{ $t('modal.multipleList.button') }}
          </v-btn>
        </div>
      </div>
    </template>
  </ui-modal-multiple-list>
</template>

<script>
import UiModalMultipleList from '@/components/UI/Modal/MultipleList.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'

export default {
  name: 'UiModalAdminsAndNotifications',
  components: {
    UiModalMultipleList,
    UiTitle,
    UiSubtitle,
  },
  props: {
    subtitleAdmins: {
      type: String,
      required: false,
      default: () => '',
    },
    subtitleNotifications: {
      type: String,
      required: false,
      default: () => '',
    },
    tabs: {
      type: Array,
      required: false,
      default: () => [],
    },
    headers: {
      type: Array,
      required: true,
    },
    multipleList: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    currentTab: '',
  }),
  mounted() {
    this.currentTab = this.tabs[0].key
  },
  methods: {
    tabChanged(tab) {
      this.currentTab = tab
    },
    emitActivateNotifications() {
      this.$emit('activateNotifications')
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-modal-list {
  &__body {
    @apply tw-flex tw-flex-col tw-h-96 tw--m-6;

    &__tabs {
      border-bottom: 1px solid map-deep-get($material-light, 'banner', 'border');

      &.theme--dark {
        border-bottom: 1px solid rgba(map-deep-get($material-dark, 'banner', 'border'), 0.12);
      }
    }

    &__table-container {
      @apply tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0;

      &__table {
        @apply tw-rounded-none tw-h-full tw-flex tw-flex-col;
      }
    }
  }
}
</style>
