<template>
  <div class="gmb-accounts">
    <UiContainer no-padding>
      <div class="gmb-accounts__table-container">
        <v-data-table
          style="cursor: pointer"
          class="gmb-accounts__table-container__table"
          :headers="headers"
          :items="accounts"
          height="100%"
          item-key="account"
          fixed-header
          :loading="saving"
          :page="accountsPagination.page"
          :server-items-length="accountsPagination.total"
          :items-per-page="accountsPagination.itemsPerPage"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100],
          }"
          @click:row="emitLoadAdminAndNotification"
          @update:options="emitLoadGmbAccounts"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <span v-if="item.clientName">
              {{ item.clientName }}
            </span>
            <v-btn v-else color="primary" rounded outlined small @click="openModalClientList(item)">
              {{ $t('gmb.admin.accounts.list.button.associate') }}
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </UiContainer>

    <ui-modal-client-list
      v-if="editingModal === 'clientList'"
      :clients="modalData"
      :subtitle="$t('gmb.admin.accounts.name')"
      @save="saveModalClientList"
      @close="closeModal"
    />
    <ui-modal-admins-and-notifications
      v-if="editingModal === 'multipleList'"
      :multiple-list="modalMultipleList"
      :headers="modalMultipleHeaders"
      :tabs="modalTabs"
      :subtitle-admins="modalSubtitleAdmins"
      :subtitle-notifications="modalSubtitleNotifications"
      @activateNotifications="emitActivateNotifications"
      @close="closeModal"
    />
  </div>
</template>

<script>
import UiContainer from '@/components/UI/Container.vue'
import UiModalClientList from '@/components/UI/Modal/ClientList.vue'
import UiModalAdminsAndNotifications from '@/components/UI/Modal/AdminsAndNotifications.vue'

export default {
  name: 'GmbAccounts',
  components: {
    UiContainer,
    UiModalClientList,
    UiModalAdminsAndNotifications,
  },
  props: {
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
    accounts: {
      type: Array,
      required: true,
    },
    accountsPagination: {
      type: Object,
      required: true,
    },
    clients: {
      type: Array,
      required: true,
    },
    adminList: {
      type: Array,
      required: false,
      default: () => [],
    },
    notificationList: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data: () => ({
    account: {},
    editingModal: '',
    modalData: null,
    buttonClicked: false,
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t('gmb.admin.accounts.list.accountName'),
          value: 'accountName',
          sortable: true,
        },
        {
          text: this.$t('gmb.admin.accounts.list.role'),
          value: 'role',
          sortable: true,
        },
        {
          text: this.$t('gmb.admin.accounts.list.type'),
          value: 'type',
          sortable: true,
        },
        {
          text: this.$t('gmb.admin.accounts.list.verificationState'),
          value: 'verificationState',
          sortable: true,
        },
        {
          text: this.$t('gmb.admin.accounts.list.actions'),
          value: 'actions',
          sortable: false,
        },
      ]
    },
    printableNotificationList() {
      if (this.notificationList?.notificationTypes?.length > 0) {
        return this.notificationList.notificationTypes.map(notif => ({ notificationType: notif }))
      }
      return []
    },
  },
  methods: {
    emitLoadGmbAccounts(params) {
      this.$emit('loadGmbAccounts', params)
    },
    emitLoadAdminAndNotification(account) {
      if (!this.buttonClicked) {
        this.account = account
        this.$emit('loadAdminAndNotification', { account })
      }
    },
    openModalMultipleList() {
      this.modalMultipleList = [this.adminList, this.printableNotificationList]
      this.modalMultipleHeaders = [
        [
          {
            text: this.$t('gmb.admin.accounts.modal.admins.admin'),
            value: 'admin',
            sortable: false,
          },
          {
            text: this.$t('gmb.admin.accounts.modal.admins.role'),
            value: 'role',
            sortable: false,
          },
        ],
        [
          {
            text: this.$t('gmb.admin.accounts.modal.notifications.notificationType'),
            value: 'notificationType',
            sortable: false,
          },
        ],
      ]
      this.modalTabs = [
        {
          label: this.$t('gmb.admin.accounts.modal.tabs.admin'),
          key: 'admin',
        },
        {
          label: this.$t('gmb.admin.accounts.modal.tabs.notification'),
          key: 'notification',
        },
      ]
      this.modalSubtitleAdmins = this.account.accountName
      this.modalSubtitleNotifications = `${this.$t('gmb.admin.accounts.modal.notifications.subtitle')} ${
        this.notificationList.topicName
          ? this.notificationList.topicName
          : this.$t('gmb.admin.accounts.modal.notifications.noSubtitle')
      }`
      this.editingModal = 'multipleList'
    },
    emitActivateNotifications() {
      this.$emit('activateNotifications', { account: this.account })
    },
    updateAdminsAndNotifications() {
      this.modalMultipleList = [this.adminList, this.printableNotificationList]
      this.modalSubtitleNotifications = `${this.$t('gmb.admin.accounts.modal.notifications.subtitle')} ${
        this.notificationList.topicName
      }`
    },
    openModalClientList(account) {
      this.buttonClicked = true

      this.account = account
      this.modalData = this.clients
      this.editingModal = 'clientList'
    },
    saveModalClientList(payload) {
      const client = payload.client
      this.$emit('sendInvitation', { client, account: this.account.name })
      this.closeModal()
    },
    closeModal() {
      this.editingModal = ''
      this.modalData = {}
      this.buttonClicked = false
    },
  },
  beforeDestroy() {
    this.$emit('clearStore')
  },
}
</script>

<style lang="scss" scoped>
.gmb-accounts {
  @apply tw-flex tw-flex-col tw-h-full;

  &__table-container {
    @apply tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0;

    &__table {
      @apply tw-rounded-none tw-h-full tw-flex tw-flex-col;
    }
  }
}
</style>
