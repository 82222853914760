<template>
  <ui-modal
    class="ui-modal-client-list"
    :title="$t('modal.clientList.title')"
    :subtitle="subtitle"
    :dialog="true"
    width="500"
    @save="save"
    @close="close"
  >
    <template v-slot:body>
      <div class="required-asterisk ui-modal-client-list__body">
        <div>
          <UiLabel id="listLabel" :label="$t('modal.clientList.list.label')" />
          <v-autocomplete
            v-model="client"
            id="clientList"
            solo
            flat
            outlined
            hide-details
            hide-no-data
            return-object
            item-text="name"
            :placeholder="$t('modal.clientList.list.placeholder')"
            :items="clients"
          />
        </div>
      </div>
    </template>
  </ui-modal>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import UiModal from '@/components/UI/Modal.vue'
import UiLabel from '@/components/UI/Label.vue'

export default {
  name: 'UiModalClientList',
  components: {
    UiModal,
    UiLabel,
  },
  mixins: [validationMixin],
  props: {
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    clients: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    client: {},
  }),
  methods: {
    save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('save', { client: this.client })
      }
    },
    close() {
      this.$emit('close')
    },
  },
  validations() {
    return {
      client: {
        required,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.ui-modal-client-list {
  &__body {
    @apply tw-flex tw-flex-col tw-gap-4;
  }
}
</style>
