<template>
  <ui-modal
    class="ui-modal-list"
    :title="$t('modal.list.title')"
    :subtitle="subtitle"
    :dialog="true"
    width="900"
    actionsLayout="center"
    @close="close"
  >
    <template v-slot:body>
      <div class="ui-modal-list__body">
        <UiContainer no-padding>
          <div class="ui-modal-list__body__table-container">
            <v-data-table
              class="ui-modal-list__body__table-container__table"
              :headers="headers"
              :items="list"
              height="100%"
              fixed-header
            />
          </div>
        </UiContainer>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn rounded plain @click="close">
        {{ $t('button.close') }}
      </v-btn>
    </template>
  </ui-modal>
</template>

<script>
import UiModal from '@/components/UI/Modal.vue'
import UiContainer from '@/components/UI/Container.vue'

export default {
  name: 'UiModalList',
  components: {
    UiModal,
    UiContainer,
  },
  props: {
    list: {
      type: Array,
      required: false,
      default: () => [],
    },
    headers: {
      type: Array,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-modal-list {
  &__body {
    @apply tw-flex tw-flex-col tw-h-96 tw--m-6;

    &__table-container {
      @apply tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0;

      &__table {
        @apply tw-rounded-none tw-h-full tw-flex tw-flex-col;
      }
    }
  }
}
</style>
