<template>
  <div class="gmb-search">
    <div class="gmb-search__toolbar" :class="{ 'theme--dark': $vuetify.theme.dark }">
      <v-text-field
        hide-details
        outlined
        solo
        flat
        dense
        :disabled="saving"
        :label="$t('common.input.search')"
        :prepend-inner-icon="icons.mdiMagnify"
        @input="onInput"
      />
    </div>

    <UiContainer no-padding>
      <div class="gmb-search__table-container">
        <v-data-table
          class="gmb-search__table-container__table"
          :headers="headers"
          :items="chains"
          height="100%"
          item-key="categoryId"
          fixed-header
          :loading="saving"
        >
          <template v-slot:[`item.chainNames`]="{ item }">
            <div class="tw-flex tw-gap-2 tw-flex-wrap tw-py-2">
              <v-chip small v-for="(chain, idx) in JSON.parse(item.chainNames)" :key="idx">
                [{{ chain.languageCode.toUpperCase() }}] {{ chain.displayName }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.websites`]="{ item }">
            <div class="tw-flex tw-gap-2 tw-flex-wrap tw-py-2">
              <v-chip small v-for="(website, idx) in JSON.parse(item.websites)" :key="idx">
                {{ website.uri }}
              </v-chip>
            </div>
          </template>
        </v-data-table>
      </div>
    </UiContainer>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { mdiMagnify } from '@mdi/js'
import UiContainer from '@/components/UI/Container.vue'

export default {
  name: 'GmbSearch',
  components: {
    UiContainer,
  },
  props: {
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
    chains: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: () => ({
    icons: {
      mdiMagnify,
    },
    search: '',
  }),
  watch: {
    search() {
      if (this.search) {
        this.$emit('loadGmbChains', this.search)
      } else {
        this.$emit('clearStore')
      }
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('gmb.admin.search.list.locationCount'),
          value: 'locationCount',
          sortable: true,
        },
        {
          text: this.$t('gmb.admin.search.list.name'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('gmb.admin.search.list.chainNames'),
          value: 'chainNames',
          sortable: false,
        },
        {
          text: this.$t('gmb.admin.search.list.websites'),
          value: 'websites',
          sortable: false,
        },
      ]
    },
  },
  methods: {
    onInput: debounce(function (value) {
      this.search = value
    }, 1000),
  },
  beforeDestroy() {
    this.$emit('clearStore')
  },
}
</script>

<style lang="scss" scoped>
.gmb-search {
  @apply tw-flex tw-flex-col tw-h-full;

  &__toolbar {
    @apply tw-grid tw-gap-6 tw-p-4 sm:tw-px-6;

    border-bottom: 1px solid map-deep-get($material-light, 'banner', 'border');
    background: map-get($material-light, 'banner', 'background');

    &.theme--dark {
      border-bottom: 1px solid rgba(map-deep-get($material-dark, 'banner', 'border'), 0.12);
      background: map-get($material-dark, 'banner', 'background');
    }
  }

  &__table-container {
    @apply tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0;

    &__table {
      @apply tw-rounded-none tw-h-full tw-flex tw-flex-col;
    }
  }
}
</style>
