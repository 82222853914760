<template>
  <ui-modal
    class="ui-modal-list"
    :title="$t('modal.list.title')"
    :subtitle="subtitle"
    :dialog="true"
    width="900"
    actionsLayout="center"
    @close="close"
  >
    <template v-slot:header>
      <slot name="header">
        <UiTitle :title="title" />
        <UiSubtitle :subtitle="subtitle" />
      </slot>
    </template>
    <template v-slot:body>
      <div class="ui-modal-list__body">
        <v-tabs v-model="currentTab" class="ui-modal-list__body__tabs">
          <v-tab class="tw-normal-case" v-for="(tab, idx) in tabs" :key="idx" :href="`#${tab.key}`">
            {{ tab.label }}
          </v-tab>
        </v-tabs>

        <UiContainer no-padding class="tw-h-full">
          <div v-for="(list, idx) in multipleList" :key="idx">
            <div class="ui-modal-list__body__table-container" v-if="currentTab === tabs[idx].key">
              <v-data-table
                class="ui-modal-list__body__table-container__table"
                :headers="headers[idx]"
                :items="list"
                height="100%"
                fixed-header
              />
            </div>
          </div>
        </UiContainer>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn rounded plain @click="close">
        {{ $t('button.close') }}
      </v-btn>
    </template>
  </ui-modal>
</template>

<script>
import UiModal from '@/components/UI/Modal.vue'
import UiContainer from '@/components/UI/Container.vue'

export default {
  name: 'UiModalMultipleList',
  components: {
    UiModal,
    UiContainer,
  },
  props: {
    subtitle: {
      type: String,
      required: false,
      default: () => '',
    },
    tabs: {
      type: Array,
      required: false,
      default: () => [],
    },
    headers: {
      type: Array,
      required: true,
    },
    multipleList: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    currentTab: '',
  }),
  mounted() {
    this.currentTab = this.tabs[0].key
  },
  watch: {
    currentTab: {
      handler() {
        this.$emit('tabChanged', this.currentTab)
      },
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-modal-list {
  &__body {
    @apply tw-flex tw-flex-col tw-h-96 tw--m-6;

    &__tabs {
      border-bottom: 1px solid map-deep-get($material-light, 'banner', 'border');

      &.theme--dark {
        border-bottom: 1px solid rgba(map-deep-get($material-dark, 'banner', 'border'), 0.12);
      }
    }

    &__table-container {
      @apply tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0;

      &__table {
        @apply tw-rounded-none tw-h-full tw-flex tw-flex-col;
      }
    }
  }
}
</style>
