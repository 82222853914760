<template>
  <div class="gmb-invitations">
    <UiContainer no-padding>
      <div class="gmb-invitations__table-container">
        <v-data-table
          class="gmb-invitations__table-container__table"
          :headers="headers"
          :items="invitationsList"
          height="100%"
          item-key="account"
          fixed-header
          :loading="saving"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn color="success" rounded outlined small @click="openModalClientList(item)">
              {{ $t('gmb.admin.invitations.list.button.accept') }}
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </UiContainer>

    <ui-modal-client-list
      v-if="editingModal === 'clientList'"
      :clients="modalData"
      :subtitle="$t('gmb.admin.invitations.name')"
      @save="saveModalClientList"
      @close="closeModal"
    />
  </div>
</template>

<script>
import UiContainer from '@/components/UI/Container.vue'
import UiModalClientList from '@/components/UI/Modal/ClientList.vue'

export default {
  name: 'GmbInvitations',
  components: {
    UiContainer,
    UiModalClientList,
  },
  props: {
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
    invitationsList: {
      type: Array,
      required: false,
      default: () => [],
    },
    clients: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    account: {},
    editingModal: '',
    modalData: null,
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t('gmb.admin.invitations.list.name'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('gmb.admin.invitations.list.role'),
          value: 'role',
          sortable: false,
        },
        {
          text: this.$t('gmb.admin.invitations.list.address'),
          value: 'address',
          sortable: false,
        },
        {
          text: this.$t('gmb.admin.invitations.list.actions'),
          value: 'actions',
          sortable: false,
        },
      ]
    },
  },
  methods: {
    openModalClientList(account) {
      this.account = account
      this.modalData = this.clients
      this.editingModal = 'clientList'
    },
    saveModalClientList(payload) {
      const client = payload.client
      this.$emit('sendInvitation', { client, account: this.account.account })
      this.closeModal()
    },
    closeModal() {
      this.editingModal = ''
      this.modalData = {}
    },
  },
  beforeDestroy() {
    this.$emit('clearStore')
  },
}
</script>

<style lang="scss" scoped>
.gmb-invitations {
  @apply tw-flex tw-flex-col tw-h-full;

  &__table-container {
    @apply tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0;

    &__table {
      @apply tw-rounded-none tw-h-full tw-flex tw-flex-col;
    }
  }
}
</style>
