<template>
  <div class="gmb-categories">
    <div class="gmb-categories__toolbar" :class="{ 'theme--dark': $vuetify.theme.dark }">
      <v-text-field
        class="tw-col-span-2"
        hide-details
        outlined
        solo
        flat
        dense
        :label="$t('common.input.search')"
        :prepend-inner-icon="icons.mdiMagnify"
        @input="onInput"
      />
      <v-autocomplete
        v-model="selectedCountry"
        id="country"
        solo
        flat
        outlined
        dense
        hide-details
        hide-no-data
        return-object
        item-text="label"
        :placeholder="$t('gmb.admin.categories.header.country.placeholder')"
        :prepend-inner-icon="icons.mdiFlagVariant"
        :items="countriesOptions"
      />
      <v-autocomplete
        v-model="selectedLanguage"
        id="language"
        solo
        flat
        outlined
        dense
        hide-details
        hide-no-data
        return-object
        item-text="label"
        :placeholder="$t('gmb.admin.categories.header.language.placeholder')"
        :prepend-inner-icon="icons.mdiWeb"
        :items="languageCodesOptions"
      />
    </div>

    <UiContainer no-padding>
      <div class="gmb-categories__table-container">
        <v-data-table
          class="gmb-categories__table-container__table"
          :headers="headers"
          :items="categoriesList"
          :search="search"
          height="100%"
          item-key="name"
          fixed-header
          :loading="saving"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="tw-flex tw-gap-4">
              <v-btn color="primary" rounded outlined small @click="emitLoadGmbAttributes(item)">
                {{ $t('gmb.admin.categories.list.button.attributes') }}
              </v-btn>
              <v-btn v-if="item.moreHoursTypes" color="primary" rounded outlined small @click="loadHoursType(item)">
                {{ $t('gmb.admin.categories.list.button.hoursType') }}
              </v-btn>
              <v-btn v-if="item.serviceTypes" color="primary" rounded outlined small @click="loadServiceTypes(item)">
                {{ $t('gmb.admin.categories.list.button.services') }}
              </v-btn>
            </div>
          </template>
          <template v-slot:[`footer.prepend`]>
            <v-btn
              v-if="hasMoreCategories"
              color="primary"
              rounded
              small
              :loading="saving"
              @click="emitLoadMoreGmbCategories"
            >
              {{ $t('button.more') }}
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </UiContainer>

    <ui-modal-list
      v-if="editingModal === 'list'"
      :list="modalList"
      :headers="modalHeaders"
      :subtitle="modalSubtitle"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { mdiMagnify, mdiFlagVariant, mdiWeb } from '@mdi/js'
import UiContainer from '@/components/UI/Container.vue'
import UiModalList from '@/components/UI/Modal/List.vue'

export default {
  name: 'GmbCategories',
  components: {
    UiContainer,
    UiModalList,
  },
  props: {
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
    availablesCountries: {
      type: Object,
      required: true,
    },
    languageCodes: {
      type: Object,
      required: true,
    },
    categoriesList: {
      type: Array,
      required: false,
      default: () => [],
    },
    hasMoreCategories: {
      type: Boolean,
      required: false,
      default: false,
    },
    attributesList: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: () => ({
    icons: {
      mdiMagnify,
      mdiFlagVariant,
      mdiWeb,
    },
    search: '',
    selectedCountry: null,
    selectedLanguage: null,
    editingModal: '',
    modalList: null,
    modalHeaders: null,
    modalSubtitle: null,
  }),
  watch: {
    search() {
      this.emitLoadGmbCategories()
    },
    selectedCountry() {
      this.emitLoadGmbCategories()
    },
    selectedLanguage() {
      this.emitLoadGmbCategories()
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('gmb.admin.categories.list.category_id'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('gmb.admin.categories.list.name'),
          value: 'displayName',
          sortable: false,
        },
        {
          text: this.$t('gmb.admin.categories.list.actions'),
          value: 'actions',
          sortable: false,
        },
      ]
    },
    countriesOptions() {
      return Object.entries(this.availablesCountries).map(obj => ({
        id: obj[0],
        label: obj[1],
      }))
    },
    languageCodesOptions() {
      return Object.entries(this.languageCodes).map(obj => ({
        id: obj[0],
        label: obj[1],
      }))
    },
    hasCountryAndLanguage() {
      if (this.selectedCountry && this.selectedLanguage) {
        return true
      }
      return false
    },
  },
  methods: {
    onInput: debounce(function (value) {
      this.search = value
    }, 300),
    emitLoadMoreGmbCategories() {
      this.$emit('loadMoreGmbCategories')
    },
    emitLoadGmbCategories() {
      if (this.hasCountryAndLanguage) {
        this.$emit('loadGmbCategories', {
          country: this.selectedCountry,
          language: this.selectedLanguage,
          search: this.search,
        })
      }
    },
    emitLoadGmbAttributes(category) {
      this.$emit('loadGmbAttributes', {
        countryCode: this.selectedCountry.id,
        languageCode: this.selectedLanguage.id,
        category,
      })
    },
    loadAttributes(category) {
      this.modalHeaders = [
        {
          text: this.$t('gmb.admin.categories.modalList.attributes.groupDisplayName'),
          value: 'groupDisplayName',
          sortable: false,
        },
        {
          text: this.$t('gmb.admin.categories.modalList.attributes.displayName'),
          value: 'displayName',
          sortable: false,
        },
        {
          text: this.$t('gmb.admin.categories.modalList.attributes.valueType'),
          value: 'valueType',
          sortable: false,
        },
        {
          text: this.$t('gmb.admin.categories.modalList.attributes.attributeId'),
          value: 'parent',
          sortable: false,
        },
      ]

      this.modalList = this.attributesList
      this.modalSubtitle = `${category.displayName} - ${category.name}`
      this.editingModal = 'list'
    },
    loadHoursType(category) {
      this.modalHeaders = [
        {
          text: this.$t('gmb.admin.categories.modalList.hoursType.displayName'),
          value: 'displayName',
          sortable: false,
        },
        {
          text: this.$t('gmb.admin.categories.modalList.hoursType.hoursTypeId'),
          value: 'hoursTypeId',
          sortable: false,
        },
        {
          text: this.$t('gmb.admin.categories.modalList.hoursType.localizedDisplayName'),
          value: 'localizedDisplayName',
          sortable: false,
        },
      ]

      this.modalList = category.moreHoursTypes
      this.modalSubtitle = `${category.displayName} - ${category.name}`
      this.editingModal = 'list'
    },
    loadServiceTypes(category) {
      this.modalHeaders = [
        {
          text: this.$t('gmb.admin.categories.modalList.serviceTypes.displayName'),
          value: 'displayName',
          sortable: false,
        },
        {
          text: this.$t('gmb.admin.categories.modalList.serviceTypes.serviceTypeId'),
          value: 'serviceTypeId',
          sortable: false,
        },
      ]

      this.modalList = category.serviceTypes
      this.modalSubtitle = `${category.displayName} - ${category.name}`
      this.editingModal = 'list'
    },
    closeModal() {
      this.editingModal = ''
      this.modalList = null
      this.modalHeaders = null
      this.modalSubtitle = null
    },
  },
  beforeDestroy() {
    this.$emit('clearStore')
  },
}
</script>

<style lang="scss" scoped>
.gmb-categories {
  @apply tw-flex tw-flex-col tw-h-full;

  &__toolbar {
    @apply tw-grid sm:tw-grid-cols-4 tw-gap-6 tw-p-4 sm:tw-px-6;

    border-bottom: 1px solid map-deep-get($material-light, 'banner', 'border');
    background: map-get($material-light, 'banner', 'background');

    &.theme--dark {
      border-bottom: 1px solid rgba(map-deep-get($material-dark, 'banner', 'border'), 0.12);
      background: map-get($material-dark, 'banner', 'background');
    }
  }

  &__table-container {
    @apply tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0;

    &__table {
      @apply tw-rounded-none tw-h-full tw-flex tw-flex-col;
    }
  }
}
</style>
