<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <div class="gmb-admin" :class="{ 'theme--dark': $vuetify.theme.dark }" v-else>
      <UiBanner
        fluid
        :title="$t('gmb.admin.name')"
        :subtitle="$t(`gmb.admin.${currentTab}.header.title`)"
        :breadcrumbs="breadcrumbs"
      />

      <v-tabs v-model="currentTab" class="gmb-admin__tabs" show-arrows>
        <v-tab class="tw-normal-case" v-for="(tab, idx) in tabs" :key="idx" :href="`#${tab.key}`">
          {{ tab.label }}
        </v-tab>
      </v-tabs>

      <!-- Categories Section -->
      <gmb-categories
        v-if="currentTab === 'categories'"
        ref="gmbCategories"
        :saving="saving"
        :availables-countries="availablesCountries"
        :language-codes="languageCodes"
        :categories-list="categoriesList"
        :attributesList="attributes"
        :has-more-categories="hasMoreCategories"
        @loadMoreGmbCategories="loadMoreGmbCategories"
        @loadGmbCategories="loadGmbCategories"
        @loadGmbAttributes="loadGmbAttributes"
        @clearStore="clearStore"
      />

      <!-- Search Section -->
      <gmb-search
        v-if="currentTab === 'search'"
        :saving="saving"
        :chains="chains"
        @loadGmbChains="loadGmbChains"
        @clearStore="clearStore"
      />

      <!-- Invitations Section -->
      <gmb-invitations
        v-if="currentTab === 'invitations'"
        :saving="saving"
        :invitations-list="invitationsList"
        :clients="clients"
        @sendInvitation="sendGmbInvitations"
        @clearStore="clearStore"
      />

      <!-- Accounts Section -->
      <gmb-accounts
        v-if="currentTab === 'accounts'"
        ref="gmbAccounts"
        :saving="saving"
        :accounts="accounts"
        :accountsPagination="accountsPagination"
        :clients="clients"
        :adminList="admins"
        :notificationList="notifications"
        @loadGmbAccounts="loadGmbAccounts"
        @loadAdminAndNotification="loadAdminAndNotification"
        @activateNotifications="activateGmbNotifications"
        @sendInvitation="associateGmbAccount"
        @clearStore="clearStore"
      />

      <!-- Settings Section -->
      <gmb-settings
        v-if="currentTab === 'settings'"
        :saving="saving"
        :not-availables-countries="notAvailablesCountries"
        :language-codes="languageCodes"
      />
    </div>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import UiBanner from '@/components/UI/Banner.vue'
import GmbCategories from '@/views/Gmb/Categories.vue'
import GmbSearch from '@/views/Gmb/Search.vue'
import GmbInvitations from '@/views/Gmb/Invitations.vue'
import GmbAccounts from '@/views/Gmb/Accounts.vue'
import GmbSettings from '@/views/Gmb/Settings.vue'

export default {
  name: 'GmbAdmin',
  components: {
    UiBanner,
    GmbCategories,
    GmbSearch,
    GmbInvitations,
    GmbAccounts,
    GmbSettings,
  },
  data: () => ({
    saving: false,
  }),
  async created() {
    this.setUpdating(true)
    await this.loadGmbInvitations()
    await this.loadGmbAccounts()
    await this.getClientsCore({ options: { size: 1000 } })
    this.setUpdating(false)
  },
  computed: {
    ...mapState({
      updating: state => state.backoffice.updating,
      availablesCountries: state => state.gmb.availablesCountries,
      notAvailablesCountries: state => state.gmb.notAvailablesCountries,
      languageCodes: state => state.gmb.languageCodes,
      chains: state => state.gmb.chains,
      accounts: state => state.gmb.accounts,
      accountsPagination: state => state.gmb.accountsPagination,
      attributes: state => state.gmb.attributes,
      clients: state => state.client.clients,
      admins: state => state.gmb.admins,
      notifications: state => state.gmb.notifications,
    }),
    ...mapGetters({
      categoriesList: 'gmb/categoriesList',
      invitationsList: 'gmb/invitationsList',
      hasMoreCategories: 'gmb/hasMoreCategories',
    }),
    breadcrumbs() {
      return [
        {
          text: this.$t('gmb.admin.name'),
          to: {
            name: 'GmbAdmin',
          },
        },
      ]
    },
    tabs() {
      return [
        {
          label: this.$t('gmb.admin.categories.name'),
          key: 'categories',
        },
        {
          label: this.$t('gmb.admin.search.name'),
          key: 'search',
        },
        {
          label: this.$t('gmb.admin.invitations.name'),
          key: 'invitations',
        },
        {
          label: this.$t('gmb.admin.accounts.name'),
          key: 'accounts',
        },
        {
          label: this.$t('gmb.admin.settings.name'),
          key: 'settings',
        },
      ]
    },
    currentTab: {
      get() {
        return this.$route.params.currentTab
      },
      set(tab) {
        return this.$router.replace({ ...this.$route, params: { ...this.$route.params, currentTab: tab } })
      },
    },
  },
  methods: {
    ...mapActions({
      getFullAccounts: 'gmb/getFullAccounts',
      setUpdating: 'backoffice/setUpdating',
      loadCategories: 'gmb/loadCategories',
      loadMoreCategories: 'gmb/loadMoreCategories',
      getChains: 'gmb/getChains',
      loadInvitations: 'gmb/loadInvitations',
      getAccounts: 'gmb/getAccounts',
      getAccountNotifications: 'gmb/getAccountNotifications',
      activateAccountNotifications: 'gmb/activateAccountNotifications',
      getAttributes: 'gmb/getAttributes',
      clearAttributes: 'gmb/clearAttributes',
      sendInvitation: 'gmb/sendInvitation',
      sendAssociateGmbAccount: 'gmb/sendAssociateGmbAccount',
      clearStore: 'gmb/clearStore',
      getClientsCore: 'client/getClientsCore',
      setAlert: 'backoffice/setAlert',
    }),
    async loadGmbCategories(payload) {
      this.saving = true
      await this.loadCategories({
        regionCode: payload.country.id,
        locale: payload.language.id,
        filter: payload.search,
        view: 'FULL',
      })
      this.saving = false
    },
    async loadMoreGmbCategories() {
      this.saving = true
      await this.loadMoreCategories()
      this.saving = false
    },
    async loadGmbChains(search) {
      this.saving = true
      await this.getChains(search)
      this.saving = false
    },
    async loadGmbInvitations() {
      this.saving = true
      await this.loadInvitations()
      this.saving = false
      return Promise.resolve()
    },
    async loadGmbAccounts(tableParams) {
      this.saving = true
      await this.getAccounts(tableParams)
      this.saving = false
      return Promise.resolve()
    },
    async loadGmbAttributes(payload) {
      this.saving = true
      this.clearAttributes()
      await this.getAttributes({
        tableParams: null,
        countryCode: payload.countryCode,
        languageCode: payload.languageCode,
        categoryId: payload.category.name,
      })
      this.$refs.gmbCategories.loadAttributes(payload.category)
      this.saving = false
    },
    async loadAdminAndNotification(payload) {
      this.saving = true
      await this.getAccountNotifications({ clientId: this.clients[0].id, account: payload.account.name })

      this.$refs.gmbAccounts.openModalMultipleList()
      this.saving = false
    },
    async activateGmbNotifications(payload) {
      this.saving = true
      await this.activateAccountNotifications({ clientId: this.clients[0].id, account: payload.account.name })

      this.$refs.gmbAccounts.updateAdminsAndNotifications()
      this.saving = false
    },
    async sendGmbInvitations(payload) {
      this.saving = true
      const client = payload.client
      const account = payload.account

      try {
        await this.sendInvitation({ account, client })
        await this.loadGmbInvitations()
        this.showNotificationSuccessInvitations()
      } catch {
        this.showNotificationError()
      }
    },
    async associateGmbAccount(payload) {
      this.saving = true
      const client = payload.client
      const account = payload.account
      try {
        await this.sendAssociateGmbAccount({ account, client })
        await this.loadGmbAccounts()
        this.showNotificationSuccessInvitations()
      } catch {
        this.showNotificationError()
      }
    },
    showNotificationSuccessInvitations() {
      this.setAlert({
        color: 'success',
        text: this.$t('notification.success.invitations'),
      })
      this.saving = false
    },
    showNotificationSuccessAccounts() {
      this.setAlert({
        color: 'success',
        text: this.$t('notification.success.accounts'),
      })
      this.saving = false
    },
    showNotificationError() {
      this.setAlert({
        color: 'error',
        text: this.$t('notification.error.default'),
      })
      this.saving = false
    },
  },
}
</script>

<style lang="scss" scoped>
.gmb-admin {
  &__tabs {
    border-bottom: 1px solid map-deep-get($material-light, 'banner', 'border');

    &.theme--dark {
      border-bottom: 1px solid rgba(map-deep-get($material-dark, 'banner', 'border'), 0.12);
    }
  }
}
</style>
